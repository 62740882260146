import React, { useEffect, useContext } from "react"
import { graphql, Link } from "gatsby"

import { IntlContext } from "../../intl"
import Layout from "../layout"
import SEO from "../seo"

export const query = graphql`
	query($id: String!) {
		prismicLinkInBio(id: { eq: $id }) {
      uid
      lang
			data {
				title {
					text
				}
				description {
					text
				}
        links {
          url {
            url
          }
          title {
            text
          }
        }
			}
      alternate_languages {
        lang
        url
        document {
          __typename
          ... on PrismicLinkInBio {
            data {
              title {
                text
              }
            }
          }
        }
      }
		}
	}
`

const LinkInBioPage = ({ location, data }) => {

  const { changeLocale, setAlts } = useContext(IntlContext)
  useEffect(() => {
    changeLocale(data.prismicLinkInBio.lang);
    setAlts(data.prismicLinkInBio.alternate_languages || [])
	}, [changeLocale, setAlts, data])
  
  return (
    <Layout container={false}>
      <SEO 
        lang={data.prismicLinkInBio.lang} 
        title={data.prismicLinkInBio.data.title.text} 
        meta={[{
          "http-equiv": "content-language", 
          content: data.prismicLinkInBio.lang
        }]} 
        links={data.prismicLinkInBio.alternate_languages.concat([data.prismicLinkInBio]).map(alt => ({
          rel: "alternate", 
          hreflang: alt.lang, 
          href: `https://www.customerradius.com${alt.url}`
        })).concat([{
          rel: "alternate", 
          hreflang: "x-default", 
          href: "https://www.customerradius.com/linkinbio"
        }])} />
      <div className="section has-background-dark has-text-centered">
        <div className="container">
          <h4 className="subtitle has-text-white">
            <small>{data.prismicLinkInBio.data.description.text}</small>
          </h4>
          {data.prismicLinkInBio.data.links.map((link,index) =>
            <div className="column is-half is-offset-one-quarter" key={index}>
              <Link to={link.url.url}>
                <div className="box ">
                  <h3 className="subtitle">
                      {link.title.text}
                  </h3>
                </div>
              </Link>
            </div>
          )}
        </div>
      </div>
    </Layout>
)}

export default LinkInBioPage
